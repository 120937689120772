import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerWhatsappMarketing() {
    var url = ConfigAPI.baseURL + "whatsapp-marketing" + Session.getToken();
    return instance.get(url);
  },
  mostrarWhatsappMarketing(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarWhatsappMarketing(data, file) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }   
    }

    var params = formData
    var url = ConfigAPI.baseURL + "whatsapp-marketing" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarWhatsappMarketing(data, file) { 
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }   
    }

    var params = formData
    var url = ConfigAPI.baseURL + "whatsapp-marketing/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarWhatsappMarketing(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filtrarWhatsappMarketing(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "whatsapp-marketing/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },   

  obtenerWhatsappMarketingContact() {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact" + Session.getToken();
    return instance.get(url);
  },
  mostrarWhatsappMarketingContact(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarWhatsappMarketingContact(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact" + Session.getToken();
    return instance.post(url,params);
  },
  editarWhatsappMarketingContact(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarWhatsappMarketingContact(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact/"+ id + Session.getToken();
    return instance.delete(url);
  },    
  filtrarWhatsappMarketingContact(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "whatsapp-marketing-contact/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },   
  importarContactsWhatsappMarketing(data, file) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists/import/contacts/CSV" + Session.getToken();
    
    return instance.post(url,params,{headers});
  },

  obtenerWhatsappMarketingLists() {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists" + Session.getToken();
    return instance.get(url);
  },
  mostrarWhatsappMarketingLists(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarWhatsappMarketingLists(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists" + Session.getToken();
    return instance.post(url,params);
  },
  editarWhatsappMarketingLists(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarWhatsappMarketingLists(id) {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists/"+ id + Session.getToken();
    return instance.delete(url);
  },
  eliminarContactoByLista(data){
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing-lists/delete-contact/"+ data.lists_id + Session.getToken();
    return instance.post(url,params);
  },  

  sendMessage(data) {
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing/message/send" + Session.getToken();
    return instance.post(url,params);
  },
  sendMessageTest(data) {
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing/message/send-test" + Session.getToken();
    return instance.post(url,params);
  },
  scheduledMessage(data) {
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing/message/scheduled" + Session.getToken();
    return instance.post(url,params);
  },
  cancelScheduledMessage(data) {
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing/message/cancel-scheduled" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerWhatsappMarketingConversations(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "notifications/conversations/whatsapp" + Session.getToken() + "&page=" + page;
    return instance.post(url, params);
  },

  obtenerNotificationWhatsapp() {
    var url = ConfigAPI.baseURL + "notifications/whatsapp" + Session.getToken();
    return instance.get(url);
  },

  obtenerConfiguracion() {
    var url = ConfigAPI.baseURL + "whatsapp-marketing-settings" + Session.getToken();
    return instance.get(url);
  },
  saveConfiguracion(data) {
    var params = data
    var url = ConfigAPI.baseURL + "whatsapp-marketing-settings/" + data.id + Session.getToken();
    return instance.post(url,params);
  },  
  
  obtenerSettingsNotificationsWhastapp(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/whatsapp/get/settings" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerQueueNotificationsWhastapp(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/whatsapp/get/queue" + Session.getToken();
    return instance.post(url,params);
  },  
  limpiarQueueNotificationsWhastapp(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/whatsapp/clear/queue" + Session.getToken();
    return instance.post(url,params);
  },  
}
export default servicesAPI;
